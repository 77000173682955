<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.13/vue.js"></script>
<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import MentorsModel from '@/modules/mentors/mentors-model';

const { fields } = MentorsModel;
const store = {
  state: {
    mentor: [],
    mainSelection: 'groups',
  },
};

export default {
  components: {
  },
  data() {
    return {
      store,
      fields,
      model: {},
    };
  },
  methods: {
    getMentorDetails() {
      store.state.mentor = [];
      const listMentorPath = '/WebListEmployeeAccess.php';
      const data = new FormData();
      data.append('id', this.$route.params.id);

      fetch(server + listMentorPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.name) {
            store.state.mentor = {
              groups: json.groups,
              id: this.$route.params.id,
              logo: json.image,
              name: json.name,
              };
          }
        });
    },
  },
  beforeMount() {
    store.state.mentor = [];
    this.getMentorDetails();
  },
};

</script>
<template lang="html">
  <div>
    <div class="d-inline-flex" style="margin-bottom: 24px">
      <div class="title-0-medium-28 basic-1" style="margin-right: 40px">
        {{ store.state.mentor.name}}
      </div>
      <div class="basic-3 subhead-reg-14" style="margin: 6px 0;display: flex;align-items: center">
        <unicon name="estate" width="16" height="16" fill="#1694D0"></unicon>
        <unicon name="angle-right" width="20" height="20" fill="#828282"/>

        <div class="d-inline" style="cursor: pointer;color: #1694D0;"
             v-on:click="$router.push(`/mentors`)">
          {{ $t("EMPLOYEE.EMPLOYEE") }}
        </div>
        <unicon name="angle-right" width="20" height="20" fill="#828282"/>
        <div class="d-inline">
          {{ store.state.mentor.name}}
        </div>
      </div>
    </div>
    <div>
      <div class="container-mentor-details">
        <div>
          <div class="mentor-details-header">
            <div class="main-select">
              <div :class="'subhead-reg-14 basic-1 selector students '
            + (store.state.mainSelection === 'groups' ? 'active' : '')"
                   v-on:click="store.state.mainSelection = 'groups'">

                <unicon height="20" width="20" name="user-circle" fill="#00BA69"></unicon>
                {{ $t("DEPARTAMENTS.GROUPS") }}

                <div class="number subhead-reg-14">
                  {{store.state.mentor.groups ? store.state.mentor.groups.length : 0}}
                </div>
              </div>
            </div>
            <div class="search-container">
              <div class="custom-input">
                <input type="text"
                       :id="fields.search.name"
                       v-model="model[fields.search.name]"
                       :placeholder="fields.search.label"
                       style="padding-left: 40px"/>
                <unicon fill="#828282" height="16" width="16" name="search"
                        style="cursor: pointer" v-on:click="getMentorDetails"/>
              </div>
            </div>
          </div>
        </div>
        <div class="main-select-groups"
             v-if="store.state.mainSelection === 'groups'">
          <b-table-simple class="custom-table">
            <b-thead class="first-thead">
              <b-tr>
                <td style="width: 6%;">
                  <div class="back" v-on:click="$router.push(`/mentors`)">
                    <unicon name="angle-left-b" y="-6"
                            width="18" height="18" fill="#828282"/>
                  </div>
                </td>
                <td style="width: 88%;" colspan="2">
                  <div class="user-details">
                    <div class="d-inline-flex">
                      <div class="user-image">
                        <img :src="store.state.mentor.logo ? store.state.mentor.logo :
                        '/media/user.jpg'" alt=""/>
                      </div>
                      <div class="user-info">
                        <div>
                          <div style="display:flex;margin-bottom: 3px">
                            <div class="headline-bold-16 basic-1 mb-0">
                              {{ store.state.mentor.name }}
                            </div>
                          </div>
                        </div>
                        <div class="caption-2-reg-12 basic-2">
                         </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td style="width: 6%;">
                  <unicon name="ellipsis-v" fill="#333333"
                          height="16" width="16" style="margin: 16px 0"/>
                </td>
              </b-tr>
            </b-thead>
            <b-thead>
              <b-tr>
                <b-th style="width: 6%;">
                  {{ $t("DEPARTAMENTS.NO") }}
                </b-th>
                <b-th style="width: 55%;">
                  {{ $t("DEPARTAMENTS.GROUP") }}
                </b-th>
                <b-th style="width: 33%;">
                  {{ $t("DEPARTAMENTS.MEMORIZATION") }}
                </b-th>
                <b-th style="width: 6%;">
                  <unicon name="setting" fill="#5E5873"/>
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(item,i) in store.state.mentor.groups" :key="i">
                <b-td  style="width: 6%;" class="item-no">
                  {{ i }}
                </b-td>
                <b-td  style="width: 55%;">
                  <div class="item-details">
                    <div class="d-inline-flex">
                      <div class="item-image">
                        <img :src="item.image ? item.image: '/media/group.jpg'"
                             alt="" />
                      </div>
                      <div class="item-info">
                          <div style="width: 100%">
                            <div style="overflow: hidden;height: 24px;
                    text-overflow: ellipsis">
                              <unicon height="20" width="20" name="user-circle"
                                      fill="#00BA69"></unicon>
                              {{item.title}}
                            </div>
                            <div class="contains">
                              <div class="ellipse green"></div>
                              <div class="caption-2-reg-12 basic-2">
                                {{item.students_count}}  {{ $t('DEPARTAMENTS.STUDENTS') }}</div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </b-td>
                <b-td  style="width: 33%;">
                  <img class="comp"  src="/media/Frame 1824.svg" alt=""/>
                </b-td>
                <b-td style="width: 6%;">
                  <unicon name="ellipsis-v" fill="#333333"
                          height="16" width="16" style="margin: 16px 0"/>
                </b-td>
              </b-tr>
              <b-tr v-if="!store.state.mentor.groups ||
               (store.state.mentor.groups && store.state.mentor.groups.length === 0)">
                <b-td colspan="4"  style="width: 100%;" class="item-no">
                  <unicon name="exclamation-triangle" fill="#E2445C" height="16" width="16"/>
                  {{ $t("ERRORS.NO_GROUPS") }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.container-mentor-details {
  padding: 0px 0px 40px;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.1));
  border-radius: 12px;
}

.container-mentor-details .mentor-details-header {
  padding: 8px;
  margin: 1px 0;
  background: #FFFFFF;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
}
.container-mentor-details .search-container {
  display: inline-flex;
  width: calc(100vw - 434px);
}
.container-mentor-details .main-select {
  background: #F5F6F8;
  border-radius: 8px;
  display: inline-flex;
  margin-right: 24px;
}
.container-mentor-details .main-select .selector {
  padding: 10px 12px;
  border-radius: 8px;
}
.number {
  display: inline-flex;
  padding: 4px 8px;
  border-radius: 6px;
  height: fit-content;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
.main-select .selector.students .number{
  background: rgba(0, 202, 114, 0.1);
  color: #00BA69;
}
.main-select .selector.students.active{
  background-color: rgba(0, 202, 114, 0.1);;
}
</style>
